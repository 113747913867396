<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="width-480 ma-auto radius-20">
				<div class="pa-13-30 bg-mafia-blue flex-row justify-space-between">
					<h5 class="color-white">프로필 이미지 편집</h5>
					<img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close.svg')" />
				</div>

				<div
					class="bg-white flex-column justify-space-between"
				>
					<div
						class="pa-30"
						style="max-height: 480px; overflow: auto"
					>
						<div class="size-px-14">프로필 이미지 또는 카드를 선택하세요</div>

						<div class="mt-20">
							<h4 class="size-px-16 font-weight-500 color-333">이미지 첨부</h4>

							<ul class="mt-10">
								<li
									class="profile-square"
								>
									<label class="cursor-pointer" style="padding: 0">
										<img
											:src="require('@/assets/image/@noimage.png')"
											class="width-100"
										>
										<inputFile @change="setUploadFile" accept="image/*" id="albumFile" />
									</label>
								</li>

								<li
									class="profile-square"
									@click="item.img_src == upload_img ? $set(item, 'img_src', '') : $set(item, 'img_src', upload_img)"
								>
									<img
										v-if="upload_img"
										:src="$request.upload_url(upload_img)"
										class="width-100"
										:class="{ on: upload_img == item.img_src}"
									/>

									<template
										v-if="upload_img"
									>
										<v-icon
											v-if="item.img_src == upload_img"
											class="position-absolute color-blue bg-white"
											style="position: absolute; left: 5px; top: 5px; border-radius: 100%"
										>mdi-checkbox-marked-circle</v-icon>
										<v-icon
											v-else
											class="position-absolute color-white"
											style="position: absolute; left: 5px; top: 5px"
										>mdi-checkbox-blank-circle</v-icon>
									</template>
								</li>
								<li
									class="profile-square"
								></li>
							</ul>
						</div>

						<div
							v-if="items_union.length > 0"
							class="mt-10"
						>
							<h4 class="size-px-16 font-weight-500 color-333">유니온 카드</h4>

							<div class="mt-10 ">
								<ul>
									<li
										v-for="(card, index) in items_union"
										:key="'union_' + index"
										class="profile-square cursor-pointer"
										@click="setFile([card.nft_card_img_url])"
									>
										<div class="position-relative">
											<img
												:src="$request.upload_url(card.nft_card_img_url)" :alt="card.cartl_nft_catgy_name"
												@error="$bus.$emit('onErrorProfileImage', $event)"
												class="width-100"
											>

											<v-icon
												v-if="card.nft_card_img_url == item.img_src"
												@click="item=card"
												class="position-absolute color-blue"
												style="position: absolute; left: 5px; top: 5px; background-color: white; padding: 0; border-radius: 100%"
											>mdi-checkbox-marked-circle</v-icon>
											<v-icon
												v-else
												@click="item={}"
												class="position-absolute color-white"
												style="position: absolute; left: 5px; top: 5px"
											>mdi-checkbox-blank-circle</v-icon>
										</div>
									</li>
								</ul>
							</div>
						</div>

						<hr
							v-if="items_nft.length > 0 && items_union.length > 0"
							class="" style="border: 1px solid rgba(255, 255, 255, 0.1); "
						/>

						<div
							v-else-if="items_union.length == 0 && items_nft.length == 0"
							style="text-align: center; color: white"
						>
							<img :src="require('@/assets/image/intro_character3_none.png')" style="width: 100px"/>
							<p style="font-family: open sans; font-weight: 400; font-size: 1.4rem; color: white; margin-top: 14px">{{  $language.nft.nft_not_taken }}</p>
						</div>

						<div class="mt-10">
							<h4 class="size-px-16 font-weight-500 color-333">MAFIA 카드</h4>
							<ul
								class="overflow-auto  mt-10"
							>
								<li
									v-for="(card, index) in items_nft"
									:key="'mafi_' + index"
									class="profile-square cursor-pointer"
									@click="setFile([card.cartl_nft_img_url])"
								>
									<div class="position-relative">
										<img
											:src="$request.upload_url(card.cartl_nft_img_url)"
											@error="$bus.$emit('onErrorProfileImage', $event)"
											class="width-100"
										>

										<v-icon
											v-if="card.cartl_nft_img_url == item.img_src"
											@click="item=card"
											class="position-absolute color-blue"
											style="position: absolute; left: 5px; top: 5px; background-color: white; padding: 0; border-radius: 100%"
										>mdi-checkbox-marked-circle</v-icon>
										<v-icon
											v-else
											@click="item={}"
											class="position-absolute color-white"
											style="position: absolute; left: 5px; top: 5px"
										>mdi-checkbox-blank-circle</v-icon>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div class="mt-auto pa-20 text-center top-line">
						<button
							class="btn-inline btn-primary radius-20 pa-10-30"
							:disabled="!item.img_src"

							@click="$emit('click', item)"
						>확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	import inputFile from "@/components/InputFile";
	export default {
		name: 'maifa1011'
		, props: ['user']
		, components: {PopupLayer, inputFile}
		, data: function(){
			return {
				program: {
					not_header: true
					, not_footer: true
				}
				, item: {
					img_src: ''
				}
				, items: []
				, upload_img: ''
				, items_union: []
				, items_nft: []
			}
		}
		, computed: {

		}
		, methods: {
			getData: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_nft
						, data: {
							member_number: this.user.member_number,
							page_number: this.$language.base.page_number,
							pagerecnum: 99
						}
						, type: true
					})
					if(result.success){

						this.items_nft = result.data.holding_cartl_nft_list
						this.items_union = result.data.holding_mafi_card_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, setUploadFile: function(e){
				this.upload_img = e[0]

				this.$set(this.item, 'img_src', e[0])
				this.$set(this.item, 'nft_card_div_code', 'CM01000001')
			}
			,setFile: function(e){

				this.$set(this.item, 'img_src', e[0])
				this.$set(this.item, 'nft_card_div_code', 'CM01000002')
			}
		}
		, created() {
			this.getData()
		}
	}
</script>

<style>
.profile-square { display: inline-block; width: 31%; margin-bottom: 10px; border-radius: 10px; overflow: hidden; position: relative}
.profile-square:not(:nth-child(3n)) { margin-right: calc(7%/2)}
.mdi-checkbox-marked-circle::before{
	color: var(--mafia-Blue);
}
</style>