<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-16 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>

				<div class="text-center">
					<div class="pa-30 profile-image cursor-pointer" @click="on1011">
						<img
							:src="$request.upload_url(item.img_src)" alt="item.file.name"
							@error="$bus.$emit('onErrorProfileImage', $event)"
							class="width-100 "
						/>

						<label class="profile-image-setting">
							<img
								:src="require('@/assets/image/web/mypage_cartel/ico_setting.svg')"
								style=""
							/>
						</label>

					</div>
				</div>
				<div>
					<div>
						<h6 class="size-px-16 font-weight-500 color-333">닉네임</h6>
						<input
							v-model="item.nickname"
							class="input-box radius-20 pa-10-20 mt-20"
							disabled
						/>
						<h6 class="mt-20 size-px-16 font-weight-500 color-333">상태메시지</h6>
						<div style="display: flex; margin-bottom: 10px;">
							<input
								v-model="item.state_message"
								class="input-box radius-20 pa-10-20 mt-20"
								style="margin: 0; padding: 1px" maxlength="30"
							/>
							<span class="input_text_count" style="position: absolute; padding: 29px; min-width: 30px; right: 20px">{{ item.state_message.length }}/30</span>
						</div>
					</div>

					<hr class="under-line mt-20 "/>

					<div class="mt-20 text-left pb-16">
						<h6 class="size-px-16 font-weight-500 color-333">관심사를 선택하세요</h6>
						<ul class="mt-16 flex-column" style="gap:16px">
							<li
								v-for="(fav, index) in item_fav"
								:key="'fav_' + index"
								class=""
								style="display: flex"
							>
								<div class="dark_checks checks_arrow cursor-pointer" style="flex-basis: 110px">
									<input type="checkbox" :id="'fav_' + fav.key" v-model="fav.isChecked" @change="checkInterested(fav.code, fav.isChecked)">
									<label :for="'fav_' + fav.key" class="cursor-pointer">{{ fav.key }}</label>
								</div>

								<div class="dark_checks checks_arrow cursor-pointer">
									<p class="size-px-13 color-888">{{ fav.description }}</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
        <hr class="under-line mt-20 "/>

        <div class="mt-20 justify-end">
			<button class="request_btn" @click="editProfile">저장</button>
        </div>
			</div>
			<div
				v-if="false"
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1">
						<ul class="settings_list">

							<li class="settings_list_item">
								<button
								>
									<div class="cell_area">
										<div class="cell_tit">휴대폰 번호</div>
										<div class="cell_action" style="font-size: 1.4rem; font-weight: 500">{{ user.mphone_number }}</div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click="$emit('to', {name: 'mafia111'}) ">
									<div class="cell_area">
										<div class="cell_tit">개인정보 보호</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div
				v-if="false"
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1">
						<ul class="settings_list">
							<li class="settings_list_item">
								<button @click="$emit('to', { name: 'notice'})">
									<div class="cell_area">
										<div class="cell_tit">공지사항</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click="$emit('to', { name: 'faq'})">
									<div class="cell_area">
										<div class="cell_tit">FAQ</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="is_app"
								class="settings_list_item"
							>
								<button @click="$emit('to', { name: 'mafia117'})">
									<div class="cell_area">
										<div class="cell_tit">최신정보</div>
										<div class="cell_action"><span class="text">{{ version }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="is_sample"
								class="settings_list_item"
							>
								<button @click="$emit('to', { name: 'reset'})">
									<div class="cell_area">
										<div class="cell_tit">테스트 버전</div>
										<div class="cell_action"><span class="text">데이터 삭제</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<mafia1011
			v-if="is_on_1011"
			:user="user"

			@cancel="is_on_1011 = false"
			@click="setFile"
		></mafia1011>
	</div>
</template>

<script>
	import MypageSide from "@/view/Mypage/mypage-lnb";
	import Mafia1011 from "@/view/Mypage/mafia101-1";
	export default {
		name: 'myPage'
		, components: {Mafia1011, MypageSide}
		, props: ['Axios', 'callBack', 'user']
		, data: function(){
			return {
				program: {
					name: '마이페이지'
					, not_footer: true
					, type: 'mypage'
					, title: '마이프로필'
					, from: 'main'
					, callBack: {
						name: 'pin_update'
					}
					, bg_contents: 'bg-gray01'
				}
				, item: {
					state_message: '',
				}
				, main_nft: {

				}
				, test : true
				, upload_img: ''
				, is_on_1011: false
			}
		}
		, computed: {
			is_sample: function(){
				let t = false
				if(process.env.VUE_APP_TYPE == 'sample'){
					t = true
				}

				return t
			}
			, device_token: function(){
				let uuid = this.$encodeStorage.getItem('_uuid')

				return uuid
			}
			, is_app: function(){
				let t = false
				return t
			}
			, item_fav: function(){
				let fav = []

				fav.push({key : 'Music', description : '음악을 좋아하고 관심을 가지고 있습니다.', code : 'CM00400001'})
				fav.push({key : 'Art', description : '예술, 디자인 등에 관심을 가지고 있습니다.', code : 'CM00400002'})
				fav.push({key : 'Fashion', description : '패션 디자인, 모델 등에 관심을 가지고 있습니다.', code : 'CM00400003'})
				fav.push({key : 'Influence', description : '유튜버, 블로거 등을 운영하는 것에 관심을 가지고 있습니다.', code : 'CM00400004'})

				return fav
			}
			, version: function() {
				let t = this.$encodeStorage.getSessionVersion()
				return  t
			}
		}
		,methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_mypage_main_info
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
						this.$set(this.item, 'img_src', result.data.profle_nft_card_img_url)
						this.item.concern_sphere_list.forEach(fast => {
							this.item_fav.forEach(now => {
								if (fast.concern_sphere_code === now.code) {
									now.isChecked = true
								}
							})
						})
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			,editProfile: async function() {

				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_profile
						, data: {
							member_number: this.user.member_number
							, img_url: this.upload_img
							, state_message: this.item.state_message
							, concern_sphere_list: this.item.concern_sphere_list
							, profle_img_upload_div_code: this.item.nft_card_div_code
						}
						, type: true
					})

					if(result.success){
						this.$log.console('success')
						this.$bus.$emit('notify', { type: 'success', message: '프로필 수정이 완료되었습니다.'})
					}else{
						throw result.message
					}
				} catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
					this.upload_img = ''
				}
			}

			, checkInterested(code, isChecked) {

				let findCodeObj = this.item.concern_sphere_list.find(el => el.concern_sphere_code === code)
					if (!isChecked) {
						let deleteIdx = this.item.concern_sphere_list.indexOf(findCodeObj)
						this.item.concern_sphere_list.splice(deleteIdx, 1)

					} else {
						if (!findCodeObj) {
						this.item.concern_sphere_list.push({concern_sphere_code : code})
					}
				}
			}
			, setFile: function(item){
				this.upload_img = item.img_src
				this.$set(this.item, 'img_src', item.img_src)
				this.$set(this.item, 'nft_card_div_code', item.nft_card_div_code)
				this.is_on_1011 = false
			}
			, on1011: function(){
				this.is_on_1011 = true
			}
		}


		,async created() {
			this.$log.console(this.program)
			await this.getData()
			await this.$emit('onLoad', this.program)
		}
		,watch: {
			callBack: {
				handler: function(call){
					if(call.name == 'pin_update'){
						this.$emit('to', { name: 'pin', params: {pin_type: 'update'}})
					}
				}
			}
		}
	}
</script>

<style>
	.profile-image{
		position: relative;
		width: 180px; margin: 0 auto;
	}
	.profile-image img{
		width: 120px; height: 120px; object-fit: cover; border-radius: 100%
	}

	.profile-image-setting {
		width: 40px; height: 40px; padding: 9px;
		border-radius: 100%;
		border: 1px solid #ddd;
		overflow: hidden; text-align: center;
		position: absolute; right: 20px; bottom: 30px;
		background-color: white;
	}

	.profile-image-setting img{
		width: 18px; height: 18px;
	}



	.dark_checks {
		position: relative
	}


	.dark_checks input[type=checkbox]+label {
		display: inline-block;
		padding: 0;
		padding-left: 34px;
		line-height: 140%;
		font-size: 14px;
		font-weight: 700;
		color: #333;
	}


	.dark_checks input[type=checkbox]+label:before {

		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 24px;
		height: 24px;
		background-color: var(--white);
		border: 1px solid var(--dark-Gray01);
		border-radius: 50%;
		content: "\e908";
		box-sizing: border-box;
		font-size: 0;
	}

  .dark_checks input[type="checkbox"]:checked + label:before {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--line-white01);
    background: var(--blue02);
    color: #fff;
    font-family: 'icomoon' !important;
    font-size: 13px;
    font-weight: 500;
    box-sizing: border-box;
  }

  .request_btn { width: 15%; text-align: center; background-color: #202891; padding: 10px; font-size: 16px; color: white; border-radius: 30px}
  .request_btn.disabled { width: 30%; text-align: center; background-color: #c2c2c2; padding: 10px; font-size: 16px; color: #797979; border-radius: 30px}

</style>